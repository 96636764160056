



























































import { defineComponent } from 'vue-demi'

const companyKeys = [
  'title',
  'kpp',
  'inn',
  'ogrn',
]

const ceoKeys = [
  'ceo',
  'ceo_post',
  'registration_date',
  'address',
  'business_address',
]

export default defineComponent({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  setup: () => ({
    companyKeys,
    ceoKeys,
  }),
})

