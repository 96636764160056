





























































































import {
  ref,
  watch,
  toRefs,
  defineComponent,
  provide,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { Company } from '@/types/api'

import { useI18n } from '@/i18n'

import { useTabs } from '@/shared/tabs'

import { companiesRepository } from '@/repositories/companies'

import Reviews from '@/components/views/CompanyReviews.vue'
import Employers from '@/components/views/CompanyEmployers.vue'
import Overview from '@/components/views/CompanyOverview.vue'
import Requisites from '@/components/views/CompanyRequisites.vue'
import CompanyDocuments from '@/components/views/CompanyDocuments.vue'

import CompanyProfile from '@/components/views/CompanyProfile.vue'

interface Props extends Record<string, any> {
  readonly id: string
  readonly section: string
}

export default defineComponent<Props>({
  components: {
    about: Overview,
    employers: Employers,
    requisites: Requisites,
    documents: CompanyDocuments,
    reviews: Reviews,
    CompanyProfile,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    section: {
      type: String,
      default: 'overview',
    },
  },

  setup(props) {
    const { id, section } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()

    const company = ref<Company>()

    provide('company', company)

    // Headers
    const items = [
      { title: i18n.t('ui.company.about'), component: 'about' },
      { title: i18n.t('ui.company.employers'), component: 'employers' },
      { title: i18n.t('ui.company.requisites'), component: 'requisites' },
      { title: i18n.t('ui.company.documents'), component: 'documents' },
      { title: i18n.t('ui.company.reviews'), component: 'reviews' },
    ]

    const tab = useTabs(items, section)

    async function loadCompany() {
      try {
        company.value = await companiesRepository.find({ id: id.value })
      } catch (error) {
        toast.error(error.toString())
      }
    }

    watch(
      () => id.value,
      loadCompany,
      { immediate: true },
    )

    return {
      tab,
      items,

      company,
      loadCompany,
    }
  },
})

