




















































import {
  computed, defineComponent, toRefs, watch,
} from 'vue-demi'

import { Company } from '@/types/api'

// @ts-ignore
import StarRating from 'vue-star-rating'
import { useStore } from '@/store'
import { Payload } from '@/types/store'
import { ACTIONS, GETTERS, MODULES } from '@/store/types'

interface Props extends Record<string, any> {
  readonly company: Company
}

export default defineComponent({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  components: {
    StarRating,
  },

  setup(props) {
    const rating = Math.round(props.company.average_rate * 100) / 100 || 0

    const store = useStore()

    const { company } = toRefs(props)

    const reviewsArrayLength = computed(() => store.getters[`${MODULES.Reviews}/${GETTERS.Reviews.getReviewsListLength}`](company.value.id))

    async function getReviewsList(id: string) {
      return store.dispatch<Payload<any>>({
        type: `${MODULES.Reviews}/${ACTIONS.Reviews.fetchReviewsList}`,
        id,
      })
    }

    watch(
      company,
      async () => {
        if (company.value) {
          await getReviewsList(company.value.id)
        }
      },
      { deep: true, immediate: true },
    )

    return {
      rating,
      reviewsArrayLength,
    }
  },
})

