






























import { defineComponent } from 'vue-demi'

import { Company } from '@/types/api'

import WorkTypes from '@/components/WorkTypes.vue'

interface Props extends Record<string, any> {
  readonly company: Company
}

export default defineComponent<Props>({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    WorkTypes,
  },
})

