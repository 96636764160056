























import {
  ref,
  watch,
  toRefs,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { User, Company } from '@/types/api'
import { VDataTableHeader } from '@/types/vuetify'

import { companiesProvider } from '@/providers/companies'

interface Props extends Record<string, any> {
  readonly company: Company
}

export default defineComponent<Props>({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { company } = toRefs(props)

    const toast = useToast()

    const headers: VDataTableHeader[] = [
      {
        text: 'ФИО',
        align: 'start',
        sortable: false,
        value: 'fio',
      },
      { text: 'Email', value: 'email' },
      { text: 'Телефон', value: 'phone_number' },
      { text: '', value: 'actions', width: 48 },
    ]

    const employersList = ref<User[]>([])

    async function loadEmployees() {
      if (!company.value) { return }

      try {
        const { results } = await companiesProvider.getCompanyEmployersList({ id: company.value.id })
        employersList.value = results
      } catch (error) {
        toast.error(`loadEmployees: ${error.error}`)
      }
    }

    watch(
      () => company.value,
      loadEmployees,
      { immediate: true },
    )

    return {
      headers,
      employersList,
      loadEmployees,
    }
  },
})

