



























import { defineComponent } from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { Company } from '@/types/api'

import PreviewModal from '@/components/widgets/PreviewModal.vue'
import DocumentsAddDialog from '@/components/dialogs/DocumentsAddDialog.vue'

interface Props extends Record<string, any> {
  readonly company: Company
}

export default defineComponent<Props>({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  components: {
    PreviewModal,
    FadeTransition,
    DocumentsAddDialog,
  },
})

